<template>
    <tr>
        <td>
            <nuxt-link :to="$prismic.linkResolver(lender)" :title="lender.data.title">
                <PrismicImage :img="lender.data.logo_squared" :w="60" class="br-round" />
            </nuxt-link>
        </td>
        <td>
            <nuxt-link :to="$prismic.linkResolver(lender)" :title="lender.data.title">
                {{ lender.data.title }}
            </nuxt-link>
        </td>
        <td>{{ lender.data.list_info }}</td>
        <td>

            <ButtonTarget
                :link="lender"
                size="sm"
                icon="fas fa-lock"
                bg="orange"
            />
        </td>
    </tr>
</template>

<script>

import { PrismicImage } from '@swegaming-ab/vue-components';
export default {

    props: [
        'lender'
    ],

    components: {
        PrismicImage
    }
}
</script>
