<template>
    <Section width="lg">
        <div class="content-menu">
            <scrollactive
                active-class="active"
                :offset="80"
                :modifyUrl="false"
            >
                <a
                    @click="expand = false"
                    class="scrollactive-item"
                    v-for="(text, index) in h2"
                    :key="index"
                    :href="'#' + $helpers.getIdString(text)"
                >
                    {{fakeIndex + index}}. {{text}}
                </a>
            </scrollactive>
        </div>

        <div
            class="rich-text"
            v-html="$prismic.asHtml(slice.primary.content1)"
        />
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            h2: [],
            fakeIndex: 1,
            expand: false
        }
    },
    methods: {
        setHeaders() {
            var arr = this.slice.primary.content1;
            for(var i = 0; i < arr.length; i++) {
                if(arr[i].type === 'heading2') {
                    this.h2.push(arr[i].text);
                }
            }
        }
    },
    mounted() {
        this.setHeaders();
    }
}
</script>

<style lang="scss" scoped>
section::v-deep {
    background: $white;

    .section-inner {
        @include flex(start,start);

        .content-menu {
            @include device(mobile) {
                display: none;
            }

            @include device(pad) {
                flex: 1;
                position: sticky;
                top: 100px;
                max-width: 200px;
            }

            @include device(desktop) {
                max-width: 300px;
            }

            nav {
                @include flex;

                a {
                    @include font-size(14px);
                    @include spacing(padding, 2);
                    color: $font;
                    width: 100%;
                    font-weight: bold;
                }
                a.active {
                    color: $orange;
                    position: relative;

                    @include psuedo {
                        position: absolute;
                        height: 100%;
                        width: 5px;
                        right: -2.5px;
                        top: 0;
                        background: $orange;
                    }
                }
            }
        }

        .rich-text {
            flex: 1;

            @include device(pad) {
                padding-left: 40px;
                @include border(left, 1px);
            }
        }
    }
}
</style>
