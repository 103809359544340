<template>
    <Section
        width="md"
        class="banner"
    >
        <HeaderSection
            :header="$prismic.asHtml(slice.primary.h1)"
            :teaser="$prismic.asText(slice.primary.teaser1)"
        />
    </Section>
</template>


<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
section::v-deep {
    background: $blue;
    color: $white;

    p { margin-bottom: 0!important; }
}
</style>
