import map from "lodash/map";
export default {
    computed: {
        lenderIds() {
            let customLendersList = this.slice.primary.lc_list;
            let selectedLendersList = this.slice.items;

            // Returns custom list
            if (customLendersList.id) return map(this.$store.state.lists[customLendersList.id].data.lenders, 'lender.id');

            // Returns selected lenders
            else if (selectedLendersList.filter(item => item.operator.id).length) return map(selectedLendersList, 'operator.id');
        }
    }
}
