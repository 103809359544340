<template>
    <Section width="lg">
        <HeaderSection
            :header="$prismic.asHtml(slice.primary.h2)"
            :teaser="$prismic.asText(slice.primary.text)"
            align="left"
        />

        <ul class="list">
            <ListItem
                v-for="(lenderId, index) in lenderIds"
                :key="index"
                :id="lenderId"
            />
        </ul>
    </Section>
</template>

<script>
import ListItem from './items/ItemData.vue';
import ListBase from '@/mixins/list-base.js';
export default {
    components: { ListItem, ListBase },
    mixins: [ ListBase ],
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
section::v-deep {
    background: $lightgray;

    .header-section { width: 100%; }

    .list {
        width: 100%;
        @include grid(1, 40px);
    }
}
</style>
