<template>
    <Section width="md">
        <i class="fas fa-exclamation-triangle" />
        <p>
            <strong>Det här är en högkostnadskredit</strong><br />
            Om du inte kan betala tillbaka hela skulden riskerar du en betalningsanmärkning. För stöd, vänd dig till budget- och skuldrådgivningen i din kommun. Kontaktuppgifter finns på hallåkonsument.se.
        </p>
    </Section>
</template>


<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>
<style lang="scss" scoped>
section::v-deep {
    background: $lightgray;
    padding-top: 40px;
    padding-bottom: 0;

    .section-inner {
        box-shadow: $bxs;
        @include spacing(padding, 6);
        background: $white;
        @include border-radius(5px);
        @include flex;

        i {
            color: $red;
            font-size: 46px;
            margin-right: 20px;
        }

        p {
            @include font-size(14px, 10px);
            flex: 1;

            strong { font-weight: bold; }
        }
    }
}
</style>
