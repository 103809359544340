<template>
    <Section
        class="sitemap-category"
        width="lg"
    >
        <div class="header">
           <nuxt-link
                v-if="slice.primary.header_link.id"
                :to="$prismic.linkResolver(slice.primary.header_link)"
            >
                <i class="fas fa-arrow-right" />
                {{$prismic.asText(slice.primary.header_text)}}
            </nuxt-link>

           <span v-else>
               <i class="fas fa-arrow-right" />
               {{$prismic.asText(slice.primary.header_text)}}
           </span>
        </div>

        <Column
            :header="$prismic.asText(slice.primary.column_1_header)"
            :links="slice.items.filter(link => link.column === '1')"
        />

        <Column
            :header="$prismic.asText(slice.primary.column_2_header)"
            :links="slice.items.filter(link => link.column === '2')"
        />

        <Column
            :header="$prismic.asText(slice.primary.column_3_header)"
            :links="slice.items.filter(link => link.column === '3')"
        />
    </Section>
</template>

<script>
import Column from './SitemapCategoryColumn'
export default {
    components: { Column },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
section::v-deep {
    padding: 20px;

    .section-inner {
        @include flex(start, start);
        @include grid(1, 40px);

        @include device(pad) {
            @include grid(4, 40px);
        }

        .header {
            text-align: left;

            span,
            a {
                @include font-size(24px);
                font-weight: bold;
                font-family: $fontText;

                i {
                    @include spacing(margin, 2, right);
                    color: $orange;
                }
            }

            a {
                color: $blue;

                &:hover { color: $darkblue; }
            }

            span { color: $font; }
        }
    }
}
</style>
