<template>
    <Section width="lg">
        <div class="grid">
            <div class="grid-container">
                <h3>{{ $store.state.translations.loan_details || 'Lånedetaljer' }}</h3>
                <ul>
                    <li
                        v-for="(list, index) in details"
                        :key="index"
                    >
                        <span>{{list.key}}</span>
                        <span>{{list.value}}</span>
                    </li>
                </ul>
            </div>

            <div class="grid-container">
                <h3>{{ $store.state.translations.company_details || 'Företagsinformation' }}</h3>
                <ul>
                    <li
                        v-for="(list, index) in companyDetails"
                        :key="index"
                    >
                        <span>{{list.header}}</span>
                        <span>{{list.data}}</span>
                    </li>
                </ul>
            </div>

            <div class="grid-container">
                <h3>{{ $store.state.translations.customer_service_opening_hours || 'Öppettider' }}</h3>
                <ul>
                    <li
                        v-for="(list, index) in openingHours"
                        :key="index"
                    >
                        <span>{{list.key}}</span>
                        <span v-if="list.value">{{list.value}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        },
        details: {
            type: Array,
            required: true,
            default: () => {}
        },
        companyDetails: {
            type: Array,
            required: true,
            default: () => {}
        },
        openingHours: {
            type: Array,
            required: true,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
section::v-deep {
    background: $lightgray;
    padding-top: 20px;

    .grid {
        @include grid(1, 20px);
        width: 100%;

        @include device(desktop) {
            @include grid(3, 20px);
        }

        &-container {
            @include spacing(padding, 6);
            @include border-radius(5px);
            box-shadow: $bxs;
            background: $white;

            h3 {
                color: $orange;
                @include spacing(margin,6,bottom);
            }

            ul {
                li {
                    @include font-size(16px, 14px);
                    @include border(bottom);
                    @include spacing(padding,2,(top,bottom));
                    color: $font;

                    @include device(padOnly) {
                        @include flex(between);
                        span { display: inline; }
                    }

                    span { display: block; }
                    span:first-child { font-weight: bold; }
                }

                li:last-child { @include border(0); }

            }
        }
    }
}
</style>
