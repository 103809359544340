<template>
    <!-- NOTE: Deprecated? -->
    <Section
        background="lblue"
        width="lg"
        class="lender-list pros"
    >
        <ul class="list">
            <ListItem
                v-for="(lenderId, index) in lenderIds"
                :key="index"
                :id="lenderId"
            />
        </ul>
    </Section>
</template>

<script>
import ListItem from './items/ItemPros.vue';
import ListBase from '@/mixins/list-base.js';
export default {
    components: { ListItem, ListBase },
    mixins: [ ListBase ],
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>
