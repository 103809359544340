<template>
    <div id="page">
        <Slices v-if="document.data.body.length" :slices="document.data.body" />
        <template v-else>

            <!-- NOTE: Should not be here long term. Just for the time being,
                       so that we can launch quicker and don't have to redo every page. -->

            <BannerSimple
                background="blue"
                :slice="this.bannerData"
            />
            <LenderList v-if="this.document.data.show_list" :header="this.document.data.list_header" :lenders="this.lenders" />
            <RichText :content="this.document.data.content" width="sm" />

        </template>

        <AuthorBox :document="document" :hreflangs="hreflangs"/>

    </div>
</template>
<script>
    import Slices from '@/components/slices/Slices.vue';

    // legacy
    import LenderList from '@/components/LenderList.vue';
    import AuthorBox from '@/components/AuthorBox.vue';

    export default {
        props: {
            document: {
                type: Object,
                required: true,
                default: () => {}
            },
            hreflangs: {
                required: true
            }
        },
        components: {
            Slices,
            // legacy
            LenderList,
            AuthorBox
        },
        data: function () {
            return {
                bannerData: {
                    primary: {
                        h1: [{
                            type: "heading1",
                            text: this.document.data.title,
                            spans: []
                        }],
                        teaser1: this.document.data.teaser
                    }
                }
            }
        },
        computed: {
            lenders: function() {
                if(this.document.data.list.id) {
                    return this.$store.state.lists[this.document.data.list.id].data.lenders;
                }

                return this.document.data.lenders;
            }
        }
    }
</script>
