<template>
    <transition name="dropdown">
        <div
            v-if="show"
            class="cta-sticky"
        >
            <PrismicImage
                :img="slice.data.logo"
                style="height: 40px;"
                class="margin-right-2"
            />

            <ButtonTarget
                icon="fas fa-lock"
                class="margin-left-2"
                bg="orange"
                :link="slice"
                size="sm"
            />
            <span
                class="compliance"
                v-if="$store.state.settings.ad_link_compliance_enabled"
            >{{ $store.state.settings.ad_link_compliance_text }}</span>
        </div>
    </transition>
</template>


<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            show: false
        }
    },
    methods: {
        onScroll(e) {
            if(window.scrollY > 600) this.show = true;
            else this.show = false;
        }
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll)
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll)
    }
}
</script>
<style lang="scss" scoped>
    .cta-sticky {
        @include flex;
        @include spacing(padding,2);
        @include border(top, 1px, $white);
        position: fixed;
        top: 60px;
        background: $white;
        box-shadow: $bxsLarge;
        width: 100%;
        z-index: 2;

        img {
            margin-right: 10px;
        }

        .compliance {
            font-family: $fontText;
            font-size: 14px;
            margin-left: 10px;
        }
    }
</style>
