<template>
    <ul
        v-if="linksList.length"
        class="sitemap-category-column"
    >
        <li>{{ header }}</li>

        <li
            v-for="(link, index) in links"
            :key="index"
        >
            <nuxt-link
                v-if="link.link.id"
                :to="$prismic.linkResolver(link.link)"
            >
                {{$prismic.asText(link.text)}}
            </nuxt-link>

            <span v-else>{{$prismic.asText(link.text)}}</span>
        </li>
    </ul>
</template>
<script>
export default {
    props: {
        header: {
            type: String,
            required: true,
            default: String
        },
        links: {
            type: Array,
            required: true,
            default: () => {}
        }
    },
    computed: {
        linksList() {
            return this.links.filter(link => link.link.id)
        }
    }
}
</script>
<style lang="scss" scoped>
    ul.sitemap-category-column {

        flex: 1;

        li:first-child {
            font-weight: bold;
            text-transform: uppercase;
            color: $orange;
        }

        li {
            font-family: $fontText;
            @include font-size(14px, 4px);
            width: 100%;
            font-weight: bold;
            @include spacing(padding, 2, (top, bottom));

            a {
                color: $blue;

                &:hover {
                    color: $darkblue;
                }
            }
        }
    }
</style>
