<template>
    <Section width="sm">
        <HeaderSection :header="$prismic.asHtml(slice.primary.h2)" />

        <div
            class="faq rich-text"
            itemscope
            itemtype="https://schema.org/FAQPage"
        >
            <QuestionAndAnswer
                v-for="(question, index) in slice.items"
                :key="index"
                :question="$prismic.asText( question.question )"
                :answer="$prismic.asHtml( question.answer )"
            />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
section::v-deep {
    background: $white;

    .faq {
        width: 100%;

        .question-answer {
            width: 100%;
            @include border(bottom);

            .question {
                padding: 20px 0;

                &-text {
                    font-family: $fontText;
                    color: $blue;
                    @include font-size(20px, 10px);
                    font-weight: bold;
                    margin-right: 20px;
                }

                &-icon {
                    color: $orange;
                }
            }

            .answer {
                padding-bottom: 20px;

                p {
                    &:first-child { margin-top: 0; }
                    &:last-child { margin-bottom: 0; }
                }
            }
        }

        .question-answer:last-child {
            margin-bottom: 0;
            border-bottom: 0;
        }
    }
}
</style>
