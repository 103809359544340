<template>
    <Section width="lg">
        <h2>{{ $store.state.translations.information || 'Information'}}</h2>

        <ul>
            <li
                v-for="(list, index) in info"
                :key="index"
                v-addClass="{threshold: .5, class: 'show'}"
            >
                <div>
                    <h3>{{list.header}}</h3>
                    <p>{{ $prismic.asText(list.data) }}</p>
                </div>
            </li>
        </ul>
    </Section>
</template>


<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        },
        info: {
            type: Array,
            required: true,
            default: () => {}
        }
    }
}
</script>
<style lang="scss" scoped>
section::v-deep {
    background: $lightgray;
    padding-bottom: 0;
    
    h2 {
        @include spacing(margin, 6, bottom);
    }

    ul {
        @include grid(1, 20px);

        @include device(pad) {
            @include grid(2, 20px);
        }

        li {
            @include spacing(padding, 6);
            @include border-radius(5px);
            box-shadow: $bxs;
            background: $white;
            @include grid(1, 15px);

            h3,
            p,
            span {
                display: block;
                width: 100%;
                text-align: left;
            }

            h3 {
                color: $orange;
                @include spacing(margin,6,bottom);
            }

            span { @include font-size(14px); }
        }
    }
}
</style>
