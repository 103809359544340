<template>
    <Section width="sm">
        <HeaderSection
            :header="$prismic.asHtml(slice.primary.h2)"
            :teaser="$prismic.asText(slice.primary.teaser1)"
        />

        <form class="form">
            <div class="form-container">
                <label for="subject">{{ $store.state.translations.cf_subject || 'Ämne' }}</label>
                <input
                    type="text"
                    name="subject"
                    v-model="subject"
                />
            </div>

            <div class="form-container">
                <label for="message">{{ $store.state.translations.cf_message || 'Meddelande' }}</label>
                <textarea
                    type="text"
                    name="message"
                    v-model="message"
                />
            </div>

            <div class="form-submit">
                <a class="btn btn-md btn-orange" :href="'mailto:contact@compinero.com?subject=' + subject + '&body=' + message">{{ $store.state.translations.cf_button || 'Skicka' }}</a>
            </div>
        </form>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data () {
        return {
            subject: '',
            message: ''
        }
    }
}
</script>
<style lang="scss" scoped>
section::v-deep {
    background: $lightgray;

    .form {
        font-family: $fontText;
        @include border-radius(5px);
        @include spacing(padding, 8);
        @include grid(1, 40px);
        position: relative;
        width: 100%;
        box-shadow: $bxs;
        background: $white;

        &-container {
            @include flex(start);

            label {
                @include spacing(margin, 2, bottom);
                font-weight: bold;
                color: $font;
            }

            input,
            textarea {
                @include spacing(padding, 2);
                width: 100%;
                background: $lightblue;
                border: 0;
                border: 2px solid $lightblue;
                resize: none;
            }

            textarea { height: 150px; }

            input:focus,
            textarea:focus { @include border(all, 2px, $orange); }
        }

        &-submit {
            @include flex;

            .btn {
                width: 100%;
            }
        }
    }
}
</style>
