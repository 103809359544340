<template>
    <Section
        width="lg"
        class="sitemap"
    >
        <HeaderSection :header="$prismic.asHtml(slice.primary.h2)" />
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        },
        slices: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    computed: {
        categories: function() {
            return this.slices.filter(slice => slice.slice_type == 'sitemap_category');
        }
    }
}
</script>

<style lang="scss" scoped>
section::v-deep {
    padding-bottom: 0;

    h2 { color: $font; }
}
</style>
