<template>

    <!-- NOTE: Site structure etc. is done through the layout
            specified in export default. Here we just need to get the
            right page type into the mix -->

    <Page v-if="document.type == 'page'" :document="this.document" :hreflangs="this.hreflangs" />
    <Lender v-else-if="document.type == 'lender'" :document="this.document" />
    <CreditCard v-else-if="document.type == 'credit_card'" :document="this.document" />
</template>

<script>


// page types
import Page from '@/components/pages/Page';
import Lender from '@/components/pages/lender/LenderPage.vue';
import CreditCard from '@/components/pages/CreditCard.vue';

// config and functions
import getDocument from '@/plugins/get-document';

// TODO: import globally...?
import { ApiHelpers } from '@swegaming-ab/swegaming-prismic';

export default {

    layout: 'site',

    // fetch the document and return data
    // to the view
    async asyncData({$prismic, context, route, error, req}) {
        let helper = new ApiHelpers($prismic, require('@/site.config.js').default);
        return await getDocument(helper, route.path, error);
    },


    components: {
        Page, Lender, CreditCard
    },

    // construct the head, just the seo title, description
    // and hreflangs for now... Hreflangs is from the asyncData
    head({$prismic}) {
        return {
            htmlAttrs: {
                lang: this.$store.state.currentSiteLocale
            },
            title: this.document.data.seo_title,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.document.data.seo_description
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: $prismic.linkResolver(this.document, true)
                }
            ].concat(this.hreflangs)
        }
    },

    mounted() {
        let links = this.$el.querySelectorAll('a[data-nuxt-link]');
        for (var i = 0; i < links.length; i++) {
            links[i].addEventListener('click', (event) => {
                event.preventDefault();
                this.$router.push(event.target.getAttribute('href'));
            });
        }
    }
}
</script>
