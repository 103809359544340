<template>
    <Section
        width="sm"
        class="banner"
    >
        <HeaderSection
            :header="`<h1>${slice.data.title}</h1>`"
            :teaser="$prismic.asText(slice.data.teaser)"
        />

        <div class="button">
            <ButtonTarget
                icon="fas fa-lock"
                bg="orange"
                :link="slice"
                size="xl"
            />
            <span
                class="compliance"
                v-if="$store.state.settings.ad_link_compliance_enabled"
            >
                {{ $store.state.settings.ad_link_compliance_text }}
            </span>
        </div>
    </Section>
</template>


<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>
<style lang="scss" scoped>
    section::v-deep {
        background: $blue;
        background: linear-gradient(to bottom, rgba(52,130,203,1) 0%,rgba(47,117,182,1) 100%)!important;
        overflow: hidden;

        .section-inner {
            z-index: 1;
            position: relative;
            @include flex;
            color: $white;

            h1 { @include spacing(margin,6,bottom); }

            .button {
                width: 100%;
                @include flex;
                @include spacing(margin,8,top);

                .compliance {
                    width: 100%;
                    text-align: center;
                    font-family: $fontText;
                    margin-top: 10px;
                }
            }
        }

        @include psuedo(before) {
            @include size(500px);
            position: absolute;
            right: 0;
            bottom: 0;
            background-image: url('~assets/images/bg-rect-corner.png');
            background-size: 100% 100%;
            opacity: .8;
            transform: scaleX(-1);
        }

        @include psuedo(after) {
            @include size(500px);
            position: absolute;
            left: 0;
            bottom: 0;
            background-image: url('~assets/images/bg-rect-corner.png');
            background-size: 100% 100%;
            opacity: .8;
            display: none;
            @include device(desktop) {
                display: block;
            }
        }
    }
</style>
