<template>
    <Section
        background="white"
        width="md"
        class="icon-containers"
    >
        <HeaderSection :header="$prismic.asHtml(slice.primary.h2)" />

        <ul>
            <li
                v-for="(item, index) in slice.items"
                :key="index"
            >
                <PrismicImage
                    :img="item.icon"
                    h="100"
                    w="100"
                />

                <span class="title">{{ $prismic.asText(item.header) }}</span>

                <div v-html="$prismic.asHtml(item.text)" />
            </li>
        </ul>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
ul {
    @include grid(1, 20px);


    @include device(pad) {
        @include grid(3, 20px);
    }

    li {
        @include spacing(padding, 6);
        @include flex(center, start);
        @include border-radius(10px);
        background: $lightgray;
        text-align: center;


        .title {
            @include font-size(20px);
            @include spacing(margin, 4, (top, bottom));
            width: 100%;
            font-family: $fontHeader;
        }

        img {
            @include size(60px);

            @include device(pad) {
                @include size(90px);
            }

            @include device(desktop) {
                @include size(100px);
            }
        }
    }
}
</style>
