<template>
    <Section
        width="md"
        class="banner"
    >
        <HeaderSection
            :header="$prismic.asHtml(slice.primary.h1)"
            :teaser="$prismic.asText(slice.primary.teaser1)"
        />

        <div class="page-links">
            <nuxt-link
                v-for="(iterator, index) in slice.items"
                :key="index"
                :to="$prismic.linkResolver(iterator.link)"
                class="page-links__link"
            >
                <PrismicImage
                    v-if="iterator.link.data"
                    :img="iterator.link.data.icon"
                    :alt="iterator.link_text"
                    w="100"
                    h="100"
                    class="link-image"
                    :lazy="false"
                />
                <span class="link-text">{{ iterator.link_text }}</span>
            </nuxt-link>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
.banner::v-deep {
    background: linear-gradient(to bottom, rgba(52,130,203,1) 0%,rgba(47,117,182,1) 100%);
    overflow: hidden;

    .section-inner {
        z-index: 1;
        position: relative;
        color: $white;
    }

    // @include psuedo(before) {
    //     @include size(500px);
    //     position: absolute;
    //     right: 0;
    //     bottom: 0;
    //     background-image: url('~assets/images/bg-rect-corner.png');
    //     background-size: 100% 100%;
    //     opacity: .8;
    //     transform: scaleY(-1) scaleX(-1);
    // }
    //
    // @include psuedo(after) {
    //     @include size(500px);
    //     position: absolute;
    //     left: 0;
    //     bottom: 0;
    //     background-image: url('~assets/images/bg-rect-corner.png');
    //     background-size: 100% 100%;
    //     opacity: .8;
    //     display: none;
    //     @include device(desktop) {
    //         display: block;
    //     }
    // }

    .page-links {
        @include spacing(margin,6,top);
        @include flex(center,stretch);
        padding: 0;
        position: relative;
        z-index: 1;
        width: 100%;

        @include device(pad) {
            @include flex;
        }

        &__link {
            @include flex;
            @include border-radius(10px);
            margin: 10px;
            width: calc(50% - 20px);
            box-shadow: $bxs;
            background: $white;
            @include spacing(padding, 4);
            color: $font;
            font-weight: bold;

            @include device(pad) {
                width: 180px;
                min-height: 160px;
            }

            &:hover { color: $blue; }

            .link-image {
                @include size(40px);

                @include device(pad) {
                    @include size(70px);
                }
            }

            .link-text {
                @include spacing(margin, 4, top);
                width: 100%;
                text-align: center;
            }
        }
    }
}
</style>
