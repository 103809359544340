<template>
    <div id="credit_card">

        <!-- TODO: Fix file -->

        <!--<b-container style="text-align: center; margin-top: 20px;">
            <a
                class="btn btn-eggshell btn-lg"
                style="background: #d8c3a5; border-color: #d8c3a5;"
                :href="$helpers.getAffiliateLink($store.state.currentSiteLocale, document)"
                rel="nofollow"
                target="_blank">
                {{ $store.state.translations.to_application || 'Till ansökan' }}
            </a>
        </b-container>-->

        <b-container class="simple-content">
            <prismic-rich-text :field="document.data.review"/>
        </b-container>
    </div>
</template>

<script>
    export default {
        props: {
            document: {
                Object
            }
        }
    }
</script>
