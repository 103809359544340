<template>
    <Section
        width="lg"
        class="lender-data"
    >
        <h2>{{ $store.state.translations.fees || 'Avgifter'}}</h2>

        <ul>
            <li
                v-for="(list, index) in fees"
                :key="index"
            >
                <h3>{{list.data}}</h3>
                <p>{{list.header}}</p>
                <span>{{list.description}}</span>
            </li>
        </ul>
    </Section>
</template>


<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        },
        fees: {
            type: Array,
            required: true,
            default: () => {}
        }
    }
}
</script>
<style lang="scss" scoped>
section::v-deep {
    background: $lightgray;
}

.lender-data {
    .section-inner { justify-content: flex-start; }

    h2 {
        @include spacing(margin, 6, bottom);
    }

    ul {
        width: 100%;

        @include grid(1, 20px);

        @include device(pad) {
            @include grid(3, 20px);
        }

        @include device(desktop) {
            @include grid(4, 20px);
        }

        li {
            @include spacing(padding, 6);
            @include border-radius(5px);
            box-shadow: $bxs;
            background: $white;
            @include grid(1, 15px);

            h3,
            p,
            span {
                display: block;
                width: 100%;
                text-align: center;
            }

            p {
                font-family: $fontText;
                @include font-size(20px);
            }

            h3 { color: $orange; }

            span { @include font-size(14px); }
        }

        li.show { animation: show .3s ease-in forwards; }

        @include device(pad) {
            @for $i from 1 through 8 {
                $delay: .1s;
                .show:nth-child(#{$i}) {
                    animation-delay: $i * $delay;
                }
            }
        }

        @keyframes show {
            from { opacity: 0; }
            to { opacity: 1; }
        }
    }
}
</style>
