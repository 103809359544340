<template>
    <Section
        width="md"
        class="text-image"
    >
        <div class="container">
            <div v-html="$prismic.asHtml(slice.primary.h2)" />
            <p>{{ $prismic.asText(slice.primary.text) }}</p>

            <ButtonNuxt
                v-if="slice.primary.link.id"
                :text="slice.primary.link_text"
                :link="slice.primary.link"
                size="lg"
                icon="fas fa-arrow-right"
                background="orange"
                class="margin-top-8"
            />
        </div>

        <div class="container">
            <PrismicImage
                :img="slice.primary.image"
                alt="Compinero"
                w="510"
                h="345"
            />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
.text-image::v-deep {
    .section-inner {
        @include grid(1, 40px);

        @include device(pad) {
            @include grid(2, 40px);
        }


        .container {
            p { @include spacing(margin, 8, top); }
            img { @include size(100%, auto); }
            .btn { @include spacing(margin, 8, top);}
        }
    }
}


</style>
