<template>
    <div class="slices">
        <template v-for="(slice, index) in slices">
            <component
                :is="sliceType(slice.slice_type)"
                :key="index"
                :slice="slice"
            />
        </template>
    </div>
</template>

<script>
import Vue from 'vue';
const ComponentContext = require.context('@/components/slices', true, /\.vue$/i, 'eager');
ComponentContext.keys().forEach((componentFilePath) => {
    const componentName = componentFilePath.split('/').pop().split('.')[0];
    Vue.component(componentName, () => ComponentContext(componentFilePath));
});

export default {
    props: {
        slices: {
            type: Array,
            required: true,
            default: () => {}
        }
    },
    methods: {
        sliceType(type) {
            if(type.includes('_')) {
                var split = type.split('_')
                for(var i = 0; i < split.length; i++) {
                    split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
                }
                return split.join("");
            }

            return type;
        }
    }
}
</script>
