<template>
    <div class="wrapper wrapper-lender">

        <Banner :slice="document" />

        <CtaSticky :slice="document" />

        <HighCost
            v-if="this.$store.state.settings.sweden_warning_enabled && parseFloat(document.data.detail_rate_highest) >= 39"
            :slice="document"
        />

        <template v-if="this.$store.state.settings.lender_page_details_enabled">
            <Fees
                :slice="document"
                :fees="fees"
            />

            <QuickInfo
                :slice="document"
                :info="quickFacts"
            />

            <CompanyInfo
                :slice="document"
                :details="details"
                :company-details="companyDetails"
                :opening-hours="openingHours"
            />
            <!-- NOTE: If we have set the lender page to contain details through prismic
                    settings. This is before we know how to do the lender page in every
                    market and how to structure the details. If this is not shown
                    we'll just have to hope for our guys to have added content in the review -->
        </template>

        <Section
            v-if="document.data.review.length"
            width="sm"
            class="rich-text-section"
        >
            <div
                class="rich-text"
                v-html="$prismic.asHtml(document.data.review)"
            ></div>
        </Section>

    </div>
</template>

<script>
import Banner from './LenderBanner.vue';
import CtaSticky from './LenderCta.vue';
import Fees from './LenderFees.vue';
import QuickInfo from './LenderQuickInfo.vue';
import CompanyInfo from './LenderInfo.vue';
import HighCost from './LenderHighCost.vue';
export default {
    components: { Banner, CtaSticky, Fees, QuickInfo, CompanyInfo, HighCost },
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    data() {
        // TODO enable different sections
        // everything to default to false, set true in prismic
        // config. Do it cleaner than its done now

        return {
            quickFacts: [
                {header: this.$store.state.translations.information_amount_and_duration || 'Belopp och löptid', data: this.document.data.qi_amount_and_duration},
                {header: this.$store.state.translations.information_credit_check || 'Kreditupplysning', data: this.document.data.qi_credit_check},
                {header: this.$store.state.translations.information_payout_times || 'Utbetalningstider', data: this.document.data.qi_payout_times},
                {header: this.$store.state.translations.information_approve_rates || 'Beviljandegrad', data: this.document.data.qi_loan_possibilities}
            ],
            fees: [
                {header: this.$store.state.translations.fees_interest || 'Ränta', data: this.document.data.fee_interest, description: this.$store.state.translations.fees_interest_desc || 'Den årliga effektiva räntan på lånet'},
                {header: this.$store.state.translations.fees_start || 'Uppläggningsavgift', data: this.document.data.fee_start, description: this.$store.state.translations.fees_start_desc || 'Kostnad för att tecka ett nytt lån'},
            ].concat(this.$store.state.settings.lender_fees_extension_enabled ? [
                {header: this.$store.state.translations.fees_extension || 'Förlängningsavgift', data: this.document.data.fee_extension, description: this.$store.state.translations.fees_extension_desc || 'Kostnad för att förlänga lånet'},
            ] : [])
            .concat([
                {header: this.$store.state.translations.fees_avi || 'Aviavgift', data: this.document.data.fee_avi, description: this.$store.state.translations.fees_avi_desc || 'Kostnad för pappersfaktura'},
                {header: this.$store.state.translations.fees_reminder || 'Påminnelseavgift', data: this.document.data.fee_reminder, description: this.$store.state.translations.fees_reminder_desc || 'Kostnad vid påminnelse'},
                {header: this.$store.state.translations.fees_late || 'Förseningsavgift', data: this.document.data.fee_late, description: this.$store.state.translations.fees_late_desc || 'Kostnad vid försening'},
                {header: this.$store.state.translations.fees_late_payment_interest || 'Dröjsmålsränta', data: this.document.data.fee_late_payment_interest, description: this.$store.state.translations.fees_late_payment_interest_desc || 'Ränta vid försening'}
            ]),
            openingHours: [
                {key: this.$store.state.translations.monday || 'Måndag', value: this.document.data.cs_opening_monday},
                {key: this.$store.state.translations.tuesday || 'Tisdag', value: this.document.data.cs_opening_tuesday},
                {key: this.$store.state.translations.wednesday || 'Onsdag', value: this.document.data.cs_opening_wednesday},
                {key: this.$store.state.translations.thursday || 'Torsdag', value: this.document.data.cs_opening_thursday},
                {key: this.$store.state.translations.friday || 'Fredag', value: this.document.data.cs_opening_friday},
                {key: this.$store.state.translations.saturday || 'Lördag', value: this.document.data.cs_opening_saturday},
                {key: this.$store.state.translations.sunday || 'Söndag', value: this.document.data.cs_opening_sunday}
            ],
            details: [
                {key: this.$store.state.translations.loan_details_rate_lowest || 'Lägsta räntan', value: this.document.data.detail_rate_lowest},
                {key: this.$store.state.translations.loan_details_rate_highest || 'Högsta räntan', value: this.document.data.detail_rate_highest},
                {key: this.$store.state.translations.loan_details_amount_lowest || 'Lägsta lånebelopp', value: this.document.data.detail_amount_lowest},
                {key: this.$store.state.translations.loan_details_amount_highest || 'Högsta lånebelopp', value: this.document.data.detail_amount_highest},
                {key: this.$store.state.translations.loan_details_credit_check_company || 'Kreditupplysningsföretag', value: this.document.data.detail_credit_check_company},
            ].concat(this.$store.state.settings.lender_uses_uc_enabled ? [
                {key: this.$store.state.translations.loan_details_uses_uc || 'Använder UC', value: this.document.data.detail_uses_uc ? (this.$store.state.translations.yes || 'Ja') : (this.$store.state.translations.no || 'Nej')},
            ] : [])
            .concat([
                {key: this.$store.state.translations.loan_details_payment_remarks_ok || 'Accepterar betalningsanmärkningar', value: this.document.data.detail_payment_remarks_ok ? (this.$store.state.translations.yes || 'Ja') : (this.$store.state.translations.no || 'Nej')},
                {key: this.$store.state.translations.loan_details_age_limit || 'Åldergräns', value: this.document.data.detail_age_limit}
            ]),
            companyDetails: [
                {header: this.$store.state.translations.company_name || 'Namn' , data: this.document.data.company_name},
                {header: this.$store.state.translations.company_registration_number || 'Org. nummer', data: this.document.data.company_registration_number},
                {header: this.$store.state.translations.company_address || 'Företagsadress', data: this.document.data.company_address},
                {header: this.$store.state.translations.company_telephone_number || 'Telefonnummer', data: this.document.data.company_phone},
                {header: this.$store.state.translations.company_email || 'E-post', data: this.document.data.company_email},
                {header: this.$store.state.translations.company_website || 'Hemsida', data: this.document.data.company_website}
            ],
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper-lender {
    position: relative;
    background: linear-gradient(to bottom, rgba(52,130,203,1) 0%,rgba(47,117,182,1) 100%)!important;

    .section-inner {
        z-index: 1;
    }
}

.rich-text-section {
    background: $white;
}
</style>
