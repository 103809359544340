<template>
    <!-- NOTE: Will this be used or should we just remove it? -->
        <ItemBase
        :id="id"
        class="width-full pros-item"
    >
        <div class="width-full flex f-p bg-white bxs-lg br-10 overflow">
            <div class="flex-3 container padding-4 flex flex-keep flex-x-start">
                <nuxt-link
                    :to="$prismic.linkResolver(lender)"
                    :title="lender.data.title"
                    class="padding-4 m-center"
                >
                    <PrismicImage
                        :img="lender.data.logo"
                    />
                </nuxt-link>
                <div class="flex flex-1">
                    <ul class="no-style">
                        <li
                            v-for="(pro, index) in lender.data.pros"
                            :key="index"
                            class="margin-y-3"
                        >
                            <i style="font-size: 20px;" class="fas fa-check-circle green"></i>
                            <span style="font-size: 20px;" class="f-p text-bold font margin-left-2 flex-1">{{pro.pro}}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="flex-1 flex container padding-4">
                <ButtonNuxt
                    :link="lender"
                    :text="$store.state.translations.read_more1 || 'Läs mer'"
                    size="read-more"
                    icon="fas fa-arrow-righ"
                    class="margin-x-4 margin-bottom-2"
                />
                <ButtonTarget
                    :link="lender"
                    size="lg"
                    icon="fas fa-lock"
                    bg="orange"
                />
                <span class="block margin-top-2 text-center width-full f-p f-14 gray">234 andra har valt det här lånet senaste 7 dagarna</span>
            </div>
        </div>
        <div class="width-full padding-4">
            <span class="f-p f-14 ">Räkneexempel: {{lender.data.compliance_example_loan}}</span>
        </div>
    </ItemBase>
</template>
<script>
import ItemBase from './ItemBase.vue';
export default {
    extends: ItemBase,
    components: { ItemBase },
    props: {
        id: {
            type: String,
            required: true
        }
    }
}
</script>
