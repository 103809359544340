<template>
    <Section
        v-if="author"
        width="md"
    >
        <div
            :class="containerWidth"
            class="author-box"
        >
            <div class="author-box__container">
                <PrismicImage
                    :img="author.data.image"
                    w="100"
                    h="100"
                    class="container-image"
                />

                <div class="container-text">
                    <span class="container-text__name">{{ author.data.name }}</span>
                    <div class="container-text__role">
                        <i class="fas fa-user-tag " />
                        {{ author.data.role }}
                    </div>


                    <a
                        :href="'mailto:' + author.data.email"
                        class="container-text__email"
                    >
                        <i class="fas fa-envelope" />
                        <span>{{ author.data.email }}</span>
                    </a>
                </div>

                <div
                    class="container-bio"
                    v-html="$prismic.asHtml(author.data.bio)"
                />

                <div class="container-published">{{ $translate('author_box_last_publication_date', 'Senast uppdaterad') }}: {{ publicationDate }}</div>

            </div>

            <div class="author-box__container">
                <ul
                    v-if="sources.length"
                    class=""
                >
                    <li>{{ $translate('author_box_sources', 'Källor') }}</li>

                    <li
                        v-for="(link, index) in sources"
                        :key="index"
                    >
                        <a
                            :href="link.link_url"
                            target="_blank"
                            class="blue"
                        >
                            {{ $helpers.truncateString(link.link_url, 50) }}
                        </a>
                    </li>
                </ul>

                <ul v-if="alternatePages.length">
                    <li>{{ $translate('author_box_other_languages', 'In other languages') }}</li>

                    <li
                        v-for="(alternate, index) in this.alternatePages"
                        :key="`authorityboxeshreflangs${index}`"
                    >
                        <a
                            :href="alternate.href"
                            target="_blank"
                        >
                            <img
                                :src="`/icons/${getAlternateIcon(alternate.hreflang)}`"
                                style="width: 25px"
                                :alt="alternate.hreflang"
                            />
                            {{ $helpers.truncateString(formatUrl(alternate.href), 40) }}
                        </a>

                    </li>
                </ul>
            </div>
        </div>
    </Section>
</template>

<script>
import config from '@/site.config.js';
import capitalize from "lodash/capitalize";
export default {
    props: {
        document: {
            type: Object,
            required: true
        },
        hreflangs: {
            type: Array,
            required: false
        }
    },
    data() {
        return {
            locales: config.locales
        }
    },
    computed: {
        publicationDate() {
            return this.document.last_publication_date.split("T")[0];
        },
        author() {
            return this.$store.state.authors[ this.document.data.author.id ]
        },
        currentHrefLang() {
            return this.$store.state.currentPrismicLocale;
        },
        alternatePages() {
            return this.hreflangs.filter(object => object.hreflang != this.currentHrefLang);
        },
        sources() {
            return this.document.data.sources.filter(this.$helpers.filterEmptyElements);
        },
        containerWidth () {
            if ( this.document.data.sources.length ) return 'section-width-md'
            else return 'section-width-sm'
        }
    },
    methods: {
        formatUrl(url) {
            const splitted = url.split('https://');
            if(splitted.length == 1) {
                return capitalize(url);
            }
            return capitalize(splitted[1]);
        },
        getAlternateIcon(hreflang) {
            return this.locales.find(locale => locale.prismic == hreflang).icon
        }
    }
}
</script>

<style lang="scss" scoped>
    section::v-deep {
        background: $lightgray;

        .author-box {
            @include flex(center,stretch);
            width: 100%;
            box-shadow: $bxs;
            background: $white;
            @include border-radius(5px);

            &__container {
                width: 100%;
                @include spacing(padding,6);

                @media screen and (min-width: 800px) {
                    width: auto;
                    flex: 1;
                }

                &:first-child {
                    @include flex(start,start);

                    .container-image {
                        @include border-radius(5px);
                        @include size(80px);
                    }

                    .container-text {
                        flex: 1;
                        @include grid(1, 10px);
                        padding-left: 20px;

                        &__name {
                            font-family: $fontHeader;
                            @include font-size(22px);
                        }

                        &__role,
                        &__email {
                            @include flex(start);
                            font-family: $fontText;

                            i {
                                margin-right: 10px;
                                color: $orange;
                            }

                            span { flex: 1; }
                        }

                        &__email {
                            color: $blue;
                            &:hover { text-decoration: underline; }
                        }
                    }

                    .container-bio {
                        @include border(top);
                        padding-top: 20px;
                        margin-top: 20px;
                    }

                    .container-published {
                        font-family: $fontText;
                        opacity: .5;
                        @include spacing(margin,6,top);
                    }
                }

                &:last-child {
                    @include grid(1, 40px);

                    ul {

                        li {
                            @include font-size(14px, 14px);

                            &:first-child { margin-bottom: 10px; }

                            a {
                                @include flex(start);
                                color: $blue;
                                &:hover { text-decoration: underline; }

                                img { margin-right: 10px; }
                            }

                            &:first-child {
                                font-family: $fontHeader;
                                @include font-size(22px);
                            }
                        }
                    }
                }
            }
        }
    }
</style>
