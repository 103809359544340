import { Helpers, ConfigHelpers } from '@swegaming-ab/swegaming-prismic';
import SiteConfig from '../site.config.js';
import { DateTime } from 'luxon';

export default async function(apiHelper, path, error) {

    let helper = new Helpers();

    let document = await fetchDocument(apiHelper, helper.splitPathToArray(path));

    if(typeof document === 'undefined') {
        return error({statusCode: 404, message: 'Not found'});
    }

    return {
        document: replace(document),
        hreflangs: await apiHelper.getHrefLangs(document)
    }
}

async function fetchDocument(apiHelper, pathArray) {

    let config = new ConfigHelpers(require('../site.config.js').default);
    let prismicLocale = config.getPrismicLocaleFromSite(first(pathArray));
    let fetchLinksBase = ['page.parent', 'page.icon'];

    if(!prismicLocale) {
        return;
    }

    if(pathArray.length == 1) {
        // homepage
        return await apiHelper.prismic.api.getByUID('page', 'home', {
            lang: prismicLocale, fetchLinks: fetchLinksBase
        });
    }
    else {
        let uid = last(pathArray);
        let result;

        if(pathArray.length == 3) {

            let prismicPostType = getPrismicPostType(prismicLocale, pathArray[1]);
            if(prismicPostType) {
                // check post types
                result = await apiHelper.prismic.api.getByUID(prismicPostType, uid, {
                    lang: prismicLocale, fetchLinks: fetchLinksBase
                }); 
            }            
        }

        if(!result) {
            // pages
            result = await apiHelper.prismic.api.getByUID('page', uid, {
                lang: prismicLocale, fetchLinks: fetchLinksBase
            });
        }

        return result;
    }
}

function first(array) {
    return array[0];
}

function last(array) {
    return array[array.length - 1];
}

function getPrismicPostType(currentPrismicLocale, postType) {
    for (var i = 0; i < SiteConfig.locales.length; i++) {
        if(SiteConfig.locales[i].prismic == currentPrismicLocale) {
            for (var j = 0; j < SiteConfig.locales[j].post_types.length; j++) {
                if(SiteConfig.locales[i].post_types[j].site == postType) {
                    return SiteConfig.locales[i].post_types[j].prismic;
                }
            }
        }
    }

    return false;
}


// TODO: Implement from nuxtjs-helpers
const replace = (json) => {
    const dt = DateTime.now();
    const currentYear = dt.year;
    return JSON.parse(
        JSON.stringify(json)
            .replace(/%year%/g, currentYear)
    );
}
