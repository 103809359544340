<template>
    <Section width="lg">
        <HeaderSection
            :header="$prismic.asHtml(slice.primary.h2)"
            :teaser="$prismic.asText(slice.primary.text)"
            align="left"
        />

        <ul
            v-if="lenderIds.length"
            class="list"
        >
            <ListItem
                v-for="(lenderId, index) in lenderIds"
                :key="index"
                :id="lenderId"
            />
        </ul>
    </Section>
</template>

<script>
import ListItem from './items/ItemCard.vue';
import ListBase from '@/mixins/list-base.js';
export default {
    components: { ListItem, ListBase },
    mixins: [ ListBase ],
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
section {
    background: $lightgray;

    .list {

        @include grid(1, 20px);

        @include device(pad) {
            @include grid(2, 20px);
        }

        @include device(desktop) {
            @include grid(3, 20px);
        }
    }
}
</style>
