<template>
    <Section width="sm">
        <HeaderSection
            :header="$prismic.asHtml(slice.primary.h2)"
            :teaser="$prismic.asText(slice.primary.teaser1)"
        />

        <ul>
            <li
                v-for="(author, index) in $store.state.authors"
                :key="index"
            >
                <div class="author">
                    <PrismicImage :img="author.data.image" />

                    <div class="author-inner">
                        <h5>{{ author.data.name }}</h5>
                        <p><i class="fas fa-user-tag" /> {{ author.data.role }}</p>
                        <a :href="'mailto:' + author.data.email"><i class="fas fa-envelope" />{{ author.data.email }}</a>
                    </div>
                </div>

                <div
                    class="bio"
                    v-html="$prismic.asHtml( author.data.bio )"
                />
            </li>
        </ul>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>
<style lang="scss" scoped>
section::v-deep {
    background: $lightgray;
}

    ul {
        width: 100%;


        li {
            @include flex;
            background: $white;
            @include border-radius(10px);
            box-shadow: $bxs;

            .author {
                @include spacing(padding, 5);
                @include flex;
                width: 100%;

                img {
                    @include size(80px);
                    @include border-radius(5px);
                }

                &-inner {
                    flex: 1;
                    @include spacing(padding, 6, left);

                    p {
                        color: $orange;
                        font-weight: bold;
                        @include spacing(margin, 1, (top, bottom));
                    }

                    a {
                        font-weight: bold;
                        color: $blue;

                        &:hover { color: $darkblue; }

                        i { @include spacing(margin,2,right); }
                    }
                }
            }

            .bio {
                @include spacing(padding, 5);
                width: 100%;
                @include border(top);
                @include spacing(padding, 4, top);
            }
        }
    }
</style>
