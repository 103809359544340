<template>
    <Section background="lblue" size="md" class="lender-list">
        <h2 class="section-header">{{ header }}</h2>
        <div class="table-overflow">
            <table class="br-5 bg-white">
                <tbody>
                    <LenderListRow v-for="(lender, index) in lenders" v-bind:key="index" :lender="$store.state.lenders[lender.lender.id]"/>
                </tbody>
            </table>
        </div>
    </Section>
</template>

<script>

    import LenderListRow from '@/components/LenderListRow.vue';
    export default {
        props: [
            'header', 'lenders'
        ],

        components: {
            LenderListRow
        }
    }
</script>
