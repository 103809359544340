<template>
    <Section width="md">
        <div class="section-header m-center" v-html="$prismic.asHtml(slice.primary.h2)"></div>
        <div class="list-latest-news width-full flex">
            <!-- NOTE: Await prismic -->
            <NewsPost
                v-for="i in 4"
                :key="i"
            />
        </div>
    </Section>
</template>

<script>
import NewsPost from './NewsPost.vue'
  export default {
      props: {
          slice: {
              type: Object,
              required: false
          }
      },
    components: { NewsPost }
  }
</script>
