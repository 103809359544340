<template>
    <Section width="md">
        <div
            v-for="(link, index) in slice.items"
            :key="index"
            class="container"
         >
            <PrismicImage
                :img="link.image"
                v-addClass="{ threshold: .8, class: 'showImg' }"
                class="container-img"
            />

            <div class="container-info">
                <span class="header">{{$prismic.asText(link.header)}}</span>

                <div v-html="$prismic.asHtml(link.content1)" />

                <div class="buttons">
                    <ButtonNuxt
                        :link="link.link"
                        :text="link.link_text"
                        size="md"
                        background="orange"
                    />

                    <ButtonNuxt
                        v-if="link.link_2_text"
                        :link="link.link_2"
                        :text="link.link_2_text"
                        size="md"
                        background="blue"
                        icon="fas fa-arrow-right"
                    />
                </div>
            </div>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
section::v-deep {
    background: $lightgray;

    .container {
        @include spacing(margin, 8, bottom);
        @include flex;
        width: 100%;
        position: relative;

        @include device(pad) {
            margin-bottom: 150px;
            height: 450px;
        }

        &-img {
            z-index: 1;
            position: absolute;
            @include border-radius(5px);
            display: none;

            @include device(pad) {
                display: block;
                opacity: 0;
                left: 0;
                max-width: 100%;
                @include size(600px, 100%);
                @include border-radius(5px);
                object-fit: cover;
            }
        }
        &-img.showImg { animation: showLeft .3s ease-in forwards; }

        &-info {
            @include spacing(padding, 8);
            @include border-radius(7px);
            box-shadow: $bxs;
            background: $white;
            z-index: 2;
            position: relative;
            width: 100%;

            @include device(pad) {
                position: absolute;
                transform: translateY(-50%);
                top: 50%;
                right: 0;
                max-width: 500px;
            }

            .header {
                @include font-size(28px);
                margin-bottom: 70px;
                display: block;
                text-align: left;
                position: relative;
                font-family: $fontHeader;

                @include psuedo {
                    position: absolute;
                    bottom: -30px;
                    left: 0;
                    height: 7px;
                    background: $blue;
                    width: 70px;
                }
            }

            .buttons {
                @include spacing(margin, 6, top);
                width: 100%;

                .btn:first-child { @include spacing(margin, 2, right); }
            }

            ul {
                @include grid(1, 10px);

                li {
                    position: relative;
                    padding-left: 35px;
                    max-width: calc(100% - 35px);
                    font-family: $fontText;
                    @include font-size(16px, 10px);

                    @include psuedo(after, '\f058') {
                        position: absolute;
                        left: 0;
                        top: 0;
                        font-size: 22px;
                        font-family: 'icomoon';
                        color: $blue;
                    }
                }
            }
        }
    }

    .container:nth-child(even) {
        .container-info {
            left: 0;
            right: auto;
        }

        .container-img {
            right: 0;
            left: auto;
        }
        .container-img.showImg { animation: showRight .3s ease-in forwards; }
    }

    @keyframes showLeft {
        from {
            opacity: 0;
            left: -50px;
        }
        to {
            opacity: 1;
            left: 0;
        }
    }
    @keyframes showRight {
        from {
            opacity: 0;
            right: -50px;
        }
        to {
            opacity: 1;
            right: 0;
        }
    }
}
</style>
