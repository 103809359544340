<template>
    <Section width="sm">
        <div
            class="rich-text"
            v-html="$prismic.asHtml(slice.primary.content1)"
        />
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>
