<template>
    <Section
        width="md"
        class="banner"
    >
        <HeaderSection
            :header="$prismic.asHtml(slice.primary.h1)"
            :teaser="$prismic.asText(slice.primary.text)"
        />
        <!-- <div class="flex-1 flex">
            <PrismicImage :img="slice.primary.icon" />
        </div> -->
    </Section>
</template>

<script>
    export default {
        props: {
            slice: {
                type: Object,
                required: true,
                default: () => {}
            }
        }
    }
</script>

<style lang="scss" scoped>
    section {
        color: $white;
        background: $blue;
    }
</style>
