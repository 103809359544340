<template>
    <nuxt-link
        to="#"
        class="flex flex-stretch br-7 margin-bottom-2 h-text-orange"
    >
        <div class="flex-1 padding-2">
            <img
                src="https://images.unsplash.com/photo-1504711434969-e33886168f5c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80"
                alt=""
                class="br-7"
            />
        </div>
        <div class="flex-2 padding-2">
            <span class="text-bold f-p f-14 gray">Kategori</span>
            <h5 class="text-bold margin-bottom-4">Detta är en header?</h5>
            <div class="date"><span>7 November, 20202</span> - <span>10 min läsning</span></div>
        </div>
    </nuxt-link>
</template>

<script>
  export default {
      props: {
          slice: {
              type: Object,
              required: false
          }
      }
  }
</script>
