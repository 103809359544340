<template>
    <Section
        width="md"
        class="banner"
    >
        <HeaderSection
            :header="$prismic.asHtml(slice.primary.h1)"
            :teaser="$prismic.asText(slice.primary.teaser1)"
        />

        <div
            class="stats"
            :class="`stats-${slice.items.length}`"
        >
            <div
                v-for="(stat, index) in slice.items"
                :key="index"
                class="stats-inner"
            >
                <h1>{{ stat.stat }}</h1>
                <span>{{ stat.stat_description }}</span>
            </div>
        </div>
    </Section>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    }
}
</script>
<style lang="scss" scoped>
section::v-deep {
    background: $blue;
    color: $white;

    .stats {
        @include spacing(margin, 8, top);
        @include grid(1, 40px);
        width: 100%;

        &-inner {
            @include grid(1, 20px);
            text-align: center;

            h1,
            span { color: $white; }

            span {
                @include font-size(20px);
                font-family: $fontText;
            }
        }
    }

    @include device(pad) {
        @for $i from 1 through 10 {
            .stats-#{$i} {
                @include grid($i, 20px);
            }
        }
    }
}
</style>
